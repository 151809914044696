@import '../../../../scss/theme-bootstrap';

.hero-block {
  margin-#{$ldirection}: auto;
  margin-#{$rdirection}: auto;
  &__content,
  &__content-wrapper,
  &__content-over-media,
  &__media-wrapper {
    display: flex;
    flex-direction: column;
  }
  &__media {
    display: block;
    width: 100%;
    height: auto;
  }
  &__media-wrapper {
    position: relative;
    text-align: center;
  }
  &__content-over-media {
    z-index: 9;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    padding: 15px;
    @include breakpoint($landscape-up) {
      padding: 30px;
    }
    .hero-block-no-media & {
      position: relative;
    }
    .playing-video & {
      @include breakpoint($landscape-up) {
        display: none;
      }
    }
  }
  &__content {
    padding: 15px;
    @include breakpoint($landscape-up) {
      padding: 30px;
    }
  }
  &__content-wrapper {
    position: relative;
    overflow: hidden;
    z-index: 1;
  }
  &__content-media {
    z-index: -1;
  }
  .content-over-media__badge-wrapper,
  .content__badge-wrapper {
    margin-bottom: 10px;
    .content-over-media__badge,
    .content__badge {
      display: inline-block;
      height: 32px;
      .mantle-media-asset {
        width: auto;
        img,
        picture,
        video {
          height: 100%;
          width: auto;
        }
      }
    }
  }
  &__link-wrapper {
    margin-top: 14px;
    margin-bottom: 14px;
    .content-block__link {
      margin-bottom: 8px;
      .link {
        font-size: 17px;
      }
    }
  }
  .content-block__content {
    height: auto;
    flex: 0;
    display: block;
  }
  .content-block__line {
    margin: 0;
  }
  .content-block__line--header {
    margin: 1px 0 1px;
  }
  .content-block__line--content {
    margin: 3px 0 0;
  }
  p {
    margin-bottom: 0;
    line-height: inherit;
  }
  .mantle-custom-text {
    text-align: unset;
  }
  .headline-6,
  .headline-5,
  .headline-4,
  .headline-3 {
    line-height: 1;
    letter-spacing: 0;
  }
  .body-1,
  .body-2 {
    line-height: 1.1;
    margin: 0;
  }
  .button {
    height: 40px;
    line-height: 2;
  }
  .link {
    @include NeueHaasGrotesk-regular;
    text-transform: none;
  }
  .basic-image {
    max-width: unset;
    img,
    picture {
      .device-pc & {
        //  aspect-ratio: 1019 / 385;
      }
      .device-mobile & {
        //  aspect-ratio: 225 / 209;
      }
      .device-pc .field-elc-mpp-content & {
        //  aspect-ratio: 1366 / 215;
      }
      .device-mobile .field-elc-mpp-content & {
        //  aspect-ratio: 225 / 120;
      }
    }
  }
  // layout option
  &--outlined-layout {
    .hero-block__content-over-media-wrapper,
    .hero-block__content-over-media,
    .hero-block__link-wrapper {
      text-align: $ldirection;
      justify-content: flex-start;
      max-width: 100%;
      align-items: flex-start;
    }
    .hero-block__content-over-media {
      padding: 0;
      z-index: 9;
      &:after {
        content: '';
        width: 1px;
        height: 100%;
        background-color: $color-white;
        display: block;
        margin-top: 15px;
      }
      &:before {
        content: '';
        width: 1px;
        height: auto;
        background-color: $color-white;
        display: block;
        position: absolute;
        bottom: 0;
        #{$rdirection}: 0;
        top: 30px;
      }
    }
    .hero-block__content-over-media-wrapper {
      border-bottom: 1px solid $color-white;
      margin: 10px 30px 30px 30px;
    }
    .content-over-media__badge {
      margin-#{$rdirection}: 5px;
    }
    .content-over-media__badge-wrapper {
      display: flex;
      align-items: flex-end;
      width: 100%;
      height: 80px;
      margin: 10px 0;
      &:after {
        content: '';
        width: auto;
        height: 50%;
        display: inline-flex;
        flex-grow: 1;
        border: 1px solid $color-white;
        border-#{$ldirection}: none;
        border-bottom: none;
      }
    }
    .hero-block__link-wrapper {
      margin-bottom: 0;
    }
  }
  .video-block {
    position: inherit;
    video {
      aspect-ratio: 16 / 9;
    }
  }
  .video-block__play-image {
    position: absolute;
    z-index: 10;
  }
  .video-detail__video-wrapper {
    position: inherit;
  }
  .video-vertical-align {
    padding: 0;
    .videojs-video__landing__inner {
      position: relative;
      .videojs-video__play {
        top: 50%;
      }
    }
    .videojs-video__landing__image {
      img {
        object-fit: contain;
        position: relative;
        #{$ldirection}: 50%;
      }
    }
  }
  .playing-video {
    .videojs-video {
      padding: 0;
      &__landing {
        display: none;
      }
      &__player {
        opacity: 1;
        position: inherit;
      }
    }
  }
  .videojs-video {
    position: inherit;
    cursor: pointer;
    &__play {
      top: 21px;
      bottom: auto;
      #{$ldirection}: 8px;
      @include breakpoint($landscape-up) {
        #{$ldirection}: 55px;
        top: 62px;
      }
    }
    &__landing {
      position: inherit;
    }
    &__player {
      opacity: 0;
    }
    &__landing__image img {
      outline-offset: -20px;
      @include breakpoint($landscape-up) {
        outline-offset: -50px;
      }
    }
  }
  .video-block__media-video {
    .play-icon-color-white {
      .videojs-video__play.video-play-trigger:before {
        background-image: url('#{$base-theme-path}img/icons/src/video-play-button--white.svg');
      }
    }
    .play-icon-color-black {
      .videojs-video__play.video-play-trigger:before {
        background-image: url('#{$base-theme-path}img/icons/src/video-play-button--black.svg');
      }
    }
  }
  .videojs-video__landing {
    z-index: unset;
  }
  .videojs-video__play {
    z-index: 99;
    &.video-play-trigger:before {
      height: 90px;
      width: 90px;
      content: '';
      border-width: 0;
      background-position: center;
      background-repeat: no-repeat;
      @include breakpoint($landscape-up) {
        height: 175px;
        width: 175px;
      }
    }
  }
  .sd-product-spp .spp-product-layout__content & {
    &__content {
      .content-block__line--content {
        .headline-4 {
          @include NeueHaasGrotesk-black;
          font-size: 26px;
          line-height: 1.5;
          @include breakpoint($landscape-up) {
            font-size: 44px;
            line-height: 1.7;
          }
        }
      }
    }
  }
}
